import { useTranslation } from "react-i18next";
import { IBreadcrumbsAndMenu } from "../../../types/global";
import { useParams, useLocation } from "react-router";
import { useFieldArray, useForm, useWatch } from "react-hook-form";
import { useEffect, useState } from "react";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import GoodsIssueHeader from "../../../components/Form/Inventory/GoodsIssue/Header";
import GoodsIssueDetail from "../../../components/Form/Inventory/GoodsIssue/Detail";
import {
  goodsIssueSchema,
  goodsIssueValidation,
} from "../../../components/Form/Inventory/GoodsIssue/schema";
import {
  GoodsIssueCreateInput,
  GoodsIssueQuery,
  GoodsIssueUpdateInput,
  InventoryDocumentType,
  ItemSkuQtysQuery,
  WarehousesQuery,
  useGoodsIssueCreateMutation,
  useGoodsIssueQuery,
  useGoodsIssueUpdateMutation,
  useInventoryDocumentNextStatusMutation,
  useItemSkuQtysQuery,
  useWarehousesQuery,
  useItemSkuDetailsQuery,
  ItemSkuDetailsQuery,
} from "../../../generated/inventory";
import { IGoodsIssue } from "../../../types/Inventory/goodsIssue";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import {
  Box,
  CircularProgress,
  Grid,
  Theme,
  useMediaQuery,
  Stack,
} from "@mui/material";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import BottomNavbar from "../../../components/UI/Navbar/BottomNavbar";
import {
  goodsIssueCreatePayloadFormatter,
  goodsIssueQueryFormatter,
  goodsIssueUpdatePayloadFormatter,
} from "../../../utils/Formatter/GoodsIssue";
import GoodsIssueItemList from "../../../components/Table/Inventory/GoodsIssue/GoodsIssueItemLIst";
import { yupResolver } from "@hookform/resolvers/yup";
import { useInventoryError } from "../../../hooks/Inventory/use-inventory-error";
import { errorMessageFormatter } from "../../../utils/Formatter/Global";
import GoodsIssueMobile from "./Mobile";
import { CustomizedBox } from "../../../components/Custom/CustomizedBox";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import { GraphQLClient } from "graphql-request";
import { useRoleSelfPermission } from "../../../hooks/use-role-permission";

const GoodsIssueContainer = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const { t } = useTranslation();
  const { state } = useLocation();

  const [goodsIssueData, setGoodsIssueData] = useState<any>(null);
  const [barcodeList, setBarcodeList] = useState<any[]>([]);

  const [createWithExport, setCreateWithExport] = useState<boolean>(false);

  const [snackbarMessage, setSnackbarMessage] = useState<string>("");
  const [disabled, setDisabled] = useState<boolean>(false);
  const [initialTraceItems, setInitialTraceItems] = useState<string[]>([]);
  const [existingInitialTraceItems, setExistingInitialTraceItems] = useState<
    string[]
  >([]);

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("inventory.index"),
      to: "/inventory",
    },
    {
      name: t("inventory.goods_issue.index"),
      to: "/inventory/goods_issue",
    },
    {
      name: Boolean(id)
        ? `${id}`
        : `${t("button.create")}${t("inventory.goods_issue.index")}`,
    },
  ];

  const graphQLClientWithHeaderItem: GraphQLClient =
    createGraphQLClientWithMiddleware("item");

  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
    reset,
  } = useForm<IGoodsIssue>({
    defaultValues: goodsIssueSchema,
    resolver: yupResolver(goodsIssueValidation),
  });

  const createdBy = useWatch({ control, name: "created_by" });

  useRoleSelfPermission(createdBy);

  const { fields, append, remove, update } = useFieldArray({
    control,
    name: "trace_entry_list",
  });

  useEffect(() => {
    if (state) {
      reset(state);
    }
  }, [reset, state]);

  const { data, isLoading, refetch } = useGoodsIssueQuery<GoodsIssueQuery>(
    graphQLClientWithHeaderItem,
    {
      uniqueInput: {
        unique_id: id,
      },
    },
    {
      enabled: false,
    }
  );

  const { refetch: fetchItemSkuDetails } =
    useItemSkuDetailsQuery<ItemSkuDetailsQuery>(
      graphQLClientWithHeaderItem,
      {
        findManyInput: {
          sku_name: {
            in: data?.goodsIssue?.trace_entry_list?.map(
              (trace: any) => trace?.item_unique_id
            ),
          },
        },
      },
      {
        enabled: false,
      }
    );

  useEffect(() => {
    if (data) {
      const traceItems =
        data?.goodsIssue?.trace_entry_list?.map(
          (trace: any) => trace.item_unique_id
        ) ?? [];
      setInitialTraceItems(traceItems);
      const getItemSkuDetails = async () => {
        const { data: result } = await fetchItemSkuDetails();
        const existingSkuNames =
          result?.itemSkuDetails?.map((sku: any) => sku.sku_name) ?? [];
        setExistingInitialTraceItems(existingSkuNames);
      };
      getItemSkuDetails();
    }
  }, [data, fetchItemSkuDetails]);

  const { data: warehouseData, isLoading: isWarehouseLoading } =
    useWarehousesQuery<WarehousesQuery>(graphQLClientWithHeaderItem);

  const { refetch: refetchSkuQty } = useItemSkuQtysQuery<ItemSkuQtysQuery>(
    graphQLClientWithHeaderItem,
    {
      findManyInput: {
        where: {
          barcode: {
            in: barcodeList,
          },
        },
      },
    },
    {
      enabled: false,
      cacheTime: 0,
    }
  );

  useEffect(() => {
    if (id) {
      const allSkuQty = async () => {
        const { data } = await refetch();
        if (data) {
          const { goodsIssue } = data;
          const traceEntryList = goodsIssue?.trace_entry_list;
          const allBarcode =
            traceEntryList
              ?.map((trace) => trace?.barcode)
              .filter((barcode) => barcode) || [];
          setBarcodeList(allBarcode);

          setGoodsIssueData(goodsIssue);
        }
      };
      allSkuQty();
    }
  }, [data, id, refetch, refetchSkuQty, reset]);

  useEffect(() => {
    if (id && goodsIssueData) {
      const formatGoodsIssue = async () => {
        const { data: allSkuQtys } = await refetchSkuQty();

        const goodsIssueType = goodsIssueData as IGoodsIssue;
        const formatGoodsIssue = goodsIssueQueryFormatter(
          goodsIssueType,
          allSkuQtys
        );
        reset(formatGoodsIssue);
      };
      formatGoodsIssue();
    }
  }, [goodsIssueData, id, refetchSkuQty, reset]);

  const { isLoading: isCreating, mutateAsync: createGoodsIssue } =
    useGoodsIssueCreateMutation<Error>(graphQLClientWithHeaderItem, {
      onSuccess: ({ goodsIssueCreate }) => {
        if (goodsIssueCreate) {
          if (createWithExport) {
            updateStatus({
              documentInput: {
                reference_document_type: InventoryDocumentType.GoodsIssue,
                unique_id: goodsIssueCreate.unique_id,
              },
            });
            enqueueSnackbar("นำออกสำเร็จ", {
              variant: "success",
            });
            setCreateWithExport(false);
          } else {
            enqueueSnackbar("สร้างใบนำออกสำเร็จ", {
              variant: "success",
            });
          }
        }

        navigate(`/inventory/goods_issue/${goodsIssueCreate?.unique_id}`);
      },
      onError: (err) => {
        const duplicatedUniqueId = errorMessageFormatter(err);
        if (duplicatedUniqueId) {
          enqueueSnackbar(duplicatedUniqueId, {
            variant: "error",
          });
        } else {
          enqueueSnackbar("สร้างใบนำออกไม่สำเร็จ", {
            variant: "error",
          });
        }
      },
    });

  const { isLoading: isUpdating, mutateAsync: updateGoodsIssue } =
    useGoodsIssueUpdateMutation<Error>(graphQLClientWithHeaderItem, {
      onSuccess: () => {
        enqueueSnackbar(`${snackbarMessage}สำเร็จ`, {
          variant: "success",
        });
      },
      onError: (err) => {
        console.error(err);
        enqueueSnackbar(`${snackbarMessage}ไม่สำเร็จ`, {
          variant: "error",
        });
      },
    });

  const { isLoading: isChanging, mutateAsync: updateStatus } =
    useInventoryDocumentNextStatusMutation<Error>(graphQLClientWithHeaderItem, {
      onSuccess: () => {
        refetch();
      },
    });

  useEffect(() => {
    if (
      !isLoading &&
      !isWarehouseLoading &&
      id &&
      data?.goodsIssue?.aggrid_status !== "draft"
    ) {
      setDisabled(true);
    }
  }, [data?.goodsIssue?.aggrid_status, id, isLoading, isWarehouseLoading]);

  const onGoodsIssueCreate = async (
    data: IGoodsIssue,
    submit: boolean,
    status: string
  ) => {
    const payload = goodsIssueCreatePayloadFormatter(
      data,
      submit
    ) as GoodsIssueCreateInput;
    await createGoodsIssue({
      createInput: { ...payload, sub_status: status },
    });
  };

  const onGoodsIssueUpdate = async (data: IGoodsIssue) => {
    if (data) {
      if (data.main_status === "draft") {
        const traces = data.trace_entry_list.map(
          (trace) => trace.item_unique_id
        );
        const matchingIntialTraces = traces.filter((trace) =>
          initialTraceItems.includes(trace)
        );
        const tracesToBeDeleted = matchingIntialTraces.filter(
          (trace) => !existingInitialTraceItems.includes(trace)
        );
        if (tracesToBeDeleted.length > 0) {
          enqueueSnackbar(
            "เอกสารมีสินค้าที่ถูกลบออกจากระบบแล้ว\nกรุณาลบสินค้า",
            {
              variant: "error",
              style: { whiteSpace: "pre-line" },
            }
          );
          return;
        }
      }
      const payload = goodsIssueUpdatePayloadFormatter(
        data
      ) as GoodsIssueUpdateInput;
      setSnackbarMessage("บันทึกร่าง");
      await updateGoodsIssue({
        uniqueInput: {
          unique_id: id,
        },
        updateInput: payload,
      });
    }
  };

  const onGoodsIssueExport = async (data: IGoodsIssue) => {
    if (data) {
      if (!id) {
        setCreateWithExport(true);
        await onGoodsIssueCreate(data, true, "finished");
      } else {
        setCreateWithExport(false);
        const payload = goodsIssueUpdatePayloadFormatter(
          data
        ) as GoodsIssueUpdateInput;
        setSnackbarMessage("นำออก");

        await updateGoodsIssue({
          uniqueInput: {
            unique_id: id,
          },
          updateInput: { ...payload, sub_status: "finished" },
        });
        await updateStatus({
          documentInput: {
            reference_document_type: InventoryDocumentType.GoodsIssue,
            unique_id: data.unique_id,
          },
        });
      }
    }
  };

  const renderButton = () => {
    switch (data?.goodsIssue?.aggrid_status) {
      case "draft":
        if (isMobile) {
          return (
            <Grid container spacing={1} mt={3}>
              <Grid item xs={6}>
                <CustomizedButton
                  fullWidth
                  size="large"
                  variant="outlined"
                  title={t("button.save_draft")}
                  disabled={isUpdating}
                  onClick={handleSubmit(onGoodsIssueUpdate)}
                />
              </Grid>
              <Grid item xs={6}>
                <CustomizedButton
                  fullWidth
                  size="large"
                  title={t("inventory.goods_issue.index")}
                  variant="contained"
                  onClick={handleSubmit(onGoodsIssueExport)}
                  disabled={isUpdating || isChanging}
                />
              </Grid>
            </Grid>
          );
        } else {
          return (
            <Stack direction="row" spacing={1} alignItems="center">
              <CustomizedButton
                title={t("button.save_draft")}
                variant="outlined"
                onClick={handleSubmit(onGoodsIssueUpdate)}
                disabled={isUpdating}
              />
              <CustomizedButton
                title={t("inventory.goods_issue.index")}
                variant="contained"
                onClick={handleSubmit(onGoodsIssueExport)}
                disabled={isUpdating || isChanging}
              />
            </Stack>
          );
        }

      case "finished":
      case "cancelled":
        return null;
      default:
        if (isMobile) {
          return (
            <Grid container spacing={1} mt={3}>
              <Grid item xs={6}>
                <CustomizedButton
                  fullWidth
                  size="large"
                  variant="outlined"
                  title={t("button.save_draft")}
                  disabled={isCreating}
                  onClick={handleSubmit((data) =>
                    onGoodsIssueCreate(data, false, "draft")
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <CustomizedButton
                  fullWidth
                  size="large"
                  title={t("inventory.goods_issue.index")}
                  variant="contained"
                  onClick={handleSubmit(onGoodsIssueExport)}
                  disabled={isUpdating || isChanging}
                />
              </Grid>
            </Grid>
          );
        } else {
          return (
            <Stack direction="row" spacing={1} alignItems="center">
              <CustomizedButton
                variant="outlined"
                title={t("button.save_draft")}
                disabled={isCreating}
                onClick={handleSubmit((data) =>
                  onGoodsIssueCreate(data, false, "draft")
                )}
              />
              <CustomizedButton
                title={t("inventory.goods_issue.index")}
                variant="contained"
                onClick={handleSubmit(onGoodsIssueExport)}
                disabled={isUpdating || isChanging}
              />
            </Stack>
          );
        }
    }
  };

  useInventoryError(errors);

  if (id && (isLoading || isWarehouseLoading)) {
    return (
      <Box
        sx={{
          height: "calc(100dvh - 125px)",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return isMobile ? (
    <form
      onKeyDown={(e) => {
        if (e.code === "Enter" || e.code === "NumpadEnter") e.preventDefault();
      }}
    >
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <GoodsIssueMobile
        control={control}
        disabled={disabled}
        errors={errors}
        getValues={getValues}
        refetch={refetch}
        setValue={setValue}
        data={data}
        reset={reset}
        warehouseData={warehouseData}
        renderButton={renderButton}
        append={append}
        fields={fields}
        remove={remove}
        update={update}
      />
    </form>
  ) : (
    <form
      onKeyDown={(e) => {
        if (e.code === "Enter" || e.code === "NumpadEnter") e.preventDefault();
      }}
    >
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <GoodsIssueHeader
        control={control}
        getValues={getValues}
        setValue={setValue}
        disabled={disabled}
        errors={errors}
        status={data?.goodsIssue?.aggrid_status}
        refetch={refetch}
      />
      <GoodsIssueDetail
        control={control}
        getValues={getValues}
        setValue={setValue}
        disabled={disabled}
        errors={errors}
        reset={reset}
        allWarehouses={warehouseData?.warehouses || []}
        status={data?.goodsIssue?.aggrid_status}
        append={append}
      />
      <CustomizedBox margin="2rem 0 4rem 0">
        <GoodsIssueItemList
          control={control}
          getValues={getValues}
          setValue={setValue}
          disabled={disabled}
          errors={errors}
          fields={fields}
          append={append}
          remove={remove}
          update={update}
          initialTraceItems={initialTraceItems}
          existingInitialTraceItems={existingInitialTraceItems}
        />
      </CustomizedBox>
      {!["finished", "cancelled"].includes(
        data?.goodsIssue?.aggrid_status || ""
      ) && <BottomNavbar>{renderButton()}</BottomNavbar>}
    </form>
  );
};

export default GoodsIssueContainer;
