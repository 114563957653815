import React, { forwardRef } from "react";
import Autocomplete, {
  AutocompleteProps,
  AutocompleteRenderOptionState,
} from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import {
  Checkbox,
  Chip,
  CircularProgress,
  InputProps,
  Typography,
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

type ComboBoxProps = AutocompleteProps<any, any, any, any, any>;

type ExtendedComboBoxProps = {
  options: ComboBoxProps["options"];
  label?: string;
  getOptionLabel?: ComboBoxProps["getOptionLabel"];
  sx?: ComboBoxProps["sx"];
  name?: string;
  onChange: ComboBoxProps["onChange"];
  onInputChange?: ComboBoxProps["onInputChange"];
  value?: ComboBoxProps["value"];
  disabled?: ComboBoxProps["disabled"];
  error?: any;
  helperText?: string;
  InputProps?: InputProps;
  required?: boolean;
  isOptionEqualToValue?: ComboBoxProps["isOptionEqualToValue"];
  testId?: string;
  ListboxProps?: ComboBoxProps["ListboxProps"];
  loading?: ComboBoxProps["loading"];
  onOpen?: ComboBoxProps["onOpen"];
  onClose?: ComboBoxProps["onClose"];
  freeSolo?: ComboBoxProps["freeSolo"];
  filterSelectedOptions?: ComboBoxProps["filterSelectedOptions"];
  multiple?: ComboBoxProps["multiple"];
  isCheckbox?: boolean;
  groupBy?: ComboBoxProps["groupBy"];
  blurOnSelect?: ComboBoxProps["blurOnSelect"];
  clearOnBlur?: ComboBoxProps["clearOnBlur"];
};

const renderCheckboxOptions = (
  props: React.HTMLAttributes<HTMLLIElement>,
  option: any,
  { selected }: AutocompleteRenderOptionState
) => {
  return (
    <li
      {...props}
      data-test-id={option.value}
      value={option.value}
      key={option + Math.random()}
    >
      <Checkbox
        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
        checkedIcon={<CheckBoxIcon fontSize="small" />}
        style={{ marginRight: 8 }}
        checked={selected}
      />
      {option.label || option}
    </li>
  );
};

const renderOptions = (
  props: React.HTMLAttributes<HTMLLIElement>,
  option: any
) => {
  if (option.id || option.label) {
    return (
      <li
        {...props}
        data-test-id={option.value}
        value={option.value}
        key={option.id}
      >
        {option.label}
      </li>
    );
  } else {
    return (
      <li {...props} data-test-id={option} value={option} key={option}>
        {option}
      </li>
    );
  }
};

const CustomizedComboBox = forwardRef(
  (
    {
      options,
      label,
      sx,
      name,
      onChange,
      onInputChange,
      value,
      disabled,
      error,
      helperText,
      InputProps,
      required,
      isOptionEqualToValue,
      testId,
      ListboxProps,
      loading,
      onOpen,
      onClose,
      freeSolo,
      filterSelectedOptions,
      multiple,
      isCheckbox,
      groupBy,
      blurOnSelect,
      clearOnBlur,
    }: ExtendedComboBoxProps,
    ref
  ) => {
    return (
      <Autocomplete
        fullWidth
        loading={loading}
        id="combo-box-demo"
        value={value || null}
        options={options}
        onChange={onChange}
        onInputChange={onInputChange}
        ListboxProps={ListboxProps}
        data-test-id={testId}
        onOpen={onOpen}
        onClose={onClose}
        clearOnBlur={clearOnBlur}
        blurOnSelect={blurOnSelect}
        freeSolo={freeSolo}
        getOptionLabel={(option) => option.label || option}
        isOptionEqualToValue={
          isOptionEqualToValue
            ? isOptionEqualToValue
            : (option, value) => {
                if (value === "") return true;
                if (option.label)
                  return option.id === value.id || option.label === value;
                else return option === value;
              }
        }
        sx={sx}
        renderOption={!isCheckbox ? renderOptions : renderCheckboxOptions}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            name={name}
            error={error}
            InputProps={{
              ...params.InputProps,
              ...InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
            size="small"
            required={required}
            helperText={helperText}
          />
        )}
        autoComplete
        includeInputInList
        disabled={disabled}
        filterSelectedOptions={filterSelectedOptions}
        multiple={multiple}
        disableCloseOnSelect={isCheckbox}
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => (
            <Chip
              label={option}
              {...getTagProps({ index })}
              color="primary"
              variant="outlined"
              size="small"
            />
          ))
        }
        groupBy={groupBy}
        renderGroup={(params) => (
          <li key={params.key}>
            <Typography sx={{ color: "#32418F", pl: 1, fontWeight: 500 }}>
              {params.group}
            </Typography>
            <li>{params.children}</li>
          </li>
        )}
      />
    );
  }
);

export default CustomizedComboBox;
