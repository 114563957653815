import { IGoodsReceive } from "../../../../types/Inventory/goodsReceive";
import dayjs from "dayjs";
import * as Yup from "yup";
import { Tracability } from "../../../../generated/inventory";

export const goodsReceiveSchema: IGoodsReceive = {
  unique_id: "",
  type: "",
  reference_unique_id: "",
  source_warehouse_unique_id: "",
  main_status: "",
  created_date: dayjs().toDate(),
  posted_date: dayjs(),
  remark: "",
  trace_entry_list: [],
  flag_status: [],
  created_by: null,
  draft_step_no: 1,
};

const goodsReceiveTraceValidation = Yup.object().shape({
  qty: Yup.number()
    .transform((value) =>
      isNaN(value) || value === null || value === undefined ? 0 : value
    )
    .min(1, "กรุณาระบุจำนวนที่จะนำเข้า")
    .required("กรุณาระบุจำนวนที่จะนำเข้า"),
});

const goodsReceiveSerialValidation = Yup.object().shape({
  qty: Yup.number().when("tracability", (tracability: any) =>
    tracability && tracability[0] === Tracability.Normal
      ? Yup.number()
          .transform((value) =>
            isNaN(value) || value === null || value === undefined ? 0 : value
          )
          .min(1, "กรุณาระบุจำนวนที่จะนำเข้า")
          .required("กรุณาระบุจำนวนที่จะนำเข้า")
      : Yup.number().nullable()
  ),
  serial_list: Yup.array().when("tracability", (tracability: any) =>
    tracability && tracability[0] === Tracability.Serial
      ? Yup.array()
          .of(goodsReceiveTraceValidation)
          .min(1, "กรุณาเพิ่ม SN ในรายการสินค้า")
      : Yup.array().nullable()
  ),
});

export const goodsReceiveValidation = Yup.object().shape({
  unique_id: Yup.string().required("กรุณาระบุเลขที่เอกสาร"),
  posted_date: Yup.date().typeError("กรุณาระบุ").required("กรุณาระบุ"),
  type: Yup.string().required("กรุณาระบุประเภท"),
  source_warehouse_unique_id: Yup.string().required("กรุณาระบุคลัง"),
  reference_unique_id: Yup.string().when("type", (type: any) => {
    if (type[0] && type[0] !== "other") {
      return Yup.string().required("กรุณาระบุเอกสารอ้างอิง");
    } else {
      return Yup.string();
    }
  }),
  trace_entry_list: Yup.array()
    .of(goodsReceiveSerialValidation)
    .min(1, "กรุณาเพิ่มสินค้า"),
});

// const goodsReceiveSerialValidation = Yup.object().shape({
//   qty: Yup.number()
//     .test(
//       "conditional-validation",
//       "กรุณาระบุจำนวนที่จะนำเข้า",
//       function (value) {
//         const { tracability, draft_step_no } = this.parent;
//         if (
//           tracability &&
//           tracability[0] === Tracability.Normal &&
//           draft_step_no &&
//           draft_step_no[0] !== 1
//         ) {
//           return value !== null && value !== undefined && value > 0;
//         }
//         return true;
//       }
//     )
//     .transform((value) =>
//       isNaN(value) || value === null || value === undefined ? 0 : value
//     ),
//   serial_list: Yup.array().test(
//     "conditional-validation",
//     "กรุณาเพิ่ม SN ในรายการสินค้า",
//     function (value) {
//       const { tracability, draft_step_no } = this.parent;
//       if (
//         tracability &&
//         tracability[0] === Tracability.Serial &&
//         draft_step_no &&
//         draft_step_no[0] !== 1
//       ) {
//         return value && value.length > 0;
//       }
//       return true;
//     }
//   ),
// });
