import {
  ColDef,
  ValueFormatterParams,
  IServerSideGetRowsParams,
  GridReadyEvent,
} from "ag-grid-community";
import { useTranslation } from "react-i18next";
import AgGrid from "../../../UI/AgGrid";
import { AgGridReact } from "ag-grid-react";
import {
  dateFilterModel,
  dateFilterParams,
} from "../../../../utils/Formatter/AgGridFilter";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../../../../services/graphqlClient";
import {
  GET_ALL_BIN_LOCATION,
  GET_ALL_UOM,
  GET_ALL_WAREHOUSES,
} from "../../../../services/AgGrid/InventoryAgGrid";
import {
  Warehouse,
  WarehouseBinLocation,
} from "../../../../generated/inventory";
import { RefObject } from "@fullcalendar/core/preact";
import { GET_TRACE_ENTRY_REPORT_VIEW } from "../../../../services/AgGrid/InventoryReportAgGrid";
import { entryTypeEngToThai } from "../../../../utils/dataTransformer";
import { formatDate } from "../../../../utils/Formatter/Date";
import { IUom } from "../../../../types/Inventory/item";
import { documentTypeFormatter } from "../../../../utils/Formatter/TraceEntry";

interface Props {
  gridRef: RefObject<AgGridReact>;
  onFilterChanged: (params: any) => void;
}

const TraceEntryReportTable = ({ gridRef, onFilterChanged }: Props) => {
  const { t } = useTranslation();

  const columnDefs: ColDef[] = [
    {
      field: "created_date",
      headerName: t("inventory.stockEntry.createdDate"),
      filter: "agDateColumnFilter",
      filterParams: dateFilterParams,
      minWidth: 200,
      valueFormatter: (params: ValueFormatterParams) =>
        formatDate(params.value),
    },
    {
      field: "posted_date",
      headerName: t("inventory.list.posted_date"),
      filter: "agDateColumnFilter",
      filterParams: dateFilterParams,
      minWidth: 200,
      valueFormatter: (params: ValueFormatterParams) =>
        formatDate(params.value),
    },
    {
      field: "item_unique_id",
      headerName: t("inventory.items.unique_id"),
      filter: "agTextColumnFilter",
    },
    {
      field: "item_name",
      headerName: t("inventory.items.name"),
      filter: "agTextColumnFilter",
    },
    {
      field: "item_seller_sku",
      headerName: t("inventory.items.seller_sku"),
      filter: "agTextColumnFilter",
    },
    {
      field: "movement_type",
      headerName: t("inventory.stockEntry.entryType"),
      valueFormatter: (params: ValueFormatterParams) => {
        if (params.value) return entryTypeEngToThai(params.value);
        else return "";
      },
      filter: "agSetColumnFilter",
      filterParams: {
        values: [
          "goods_receive",
          "goods_issue",
          "goods_transfer",
          "goods_adjust",
        ],
        valueFormatter: (params: ValueFormatterParams) =>
          entryTypeEngToThai(params.value),
      },
    },
    {
      field: "document_type",
      headerName: t("inventory.type.document"),
      valueFormatter: (params: ValueFormatterParams) => {
        if (params.value) return documentTypeFormatter(params.value);
        else return "";
      },
      filter: "agSetColumnFilter",
      filterParams: {
        values: [
          "sales_order",
          "purchase_order",
          "manufacture_order",
          "purchase_return",
          "other",
        ],
        valueFormatter: (params: ValueFormatterParams) =>
          documentTypeFormatter(params.value),
      },
    },
    {
      field: "qty",
      headerName: t("inventory.quantities.index"),
      filter: false,
      headerClass: "ag-end-header",
      suppressMenu: true,
      cellStyle: {
        display: "flex",
        justifyContent: "end",
      },
    },
    {
      field: "uom_name",
      headerName: t("inventory.unit"),
      filter: "agSetColumnFilter",
      filterParams: {
        values: async (params: any) => {
          const graphQLClientWithHeaderItem: GraphQLClient =
            createGraphQLClientWithMiddleware("item");
          const { uoms } = await graphQLClientWithHeaderItem.request(
            GET_ALL_UOM
          );
          const uomsName = uoms.map((uom: IUom) => uom.name);
          params.success(uomsName);
        },
      },
    },
    {
      field: "document_unique_id",
      headerName: t("inventory.documentId"),
      filter: "agTextColumnFilter",
    },
    {
      field: "reference_unique_id",
      headerName: t("inventory.stockEntry.referenceDocument"),
      filter: "agTextColumnFilter",
    },
    {
      field: "source_warehouse_unique_id",
      headerName: t("inventory.list.sourceWarehousDocId"),
      filter: "agSetColumnFilter",
      filterParams: {
        values: async (params: any) => {
          const graphQLClientWithHeaderItem: GraphQLClient =
            createGraphQLClientWithMiddleware("item");
          const { warehouses } = await graphQLClientWithHeaderItem.request(
            GET_ALL_WAREHOUSES
          );
          const warehousesUniqueId = warehouses.map(
            (warehouse: Warehouse) => warehouse.unique_id
          );
          params.success(warehousesUniqueId);
        },
      },
    },
    {
      field: "source_warehouse_name",
      headerName: t("inventory.list.sourceWarehouse"),
      filter: "agSetColumnFilter",
      filterParams: {
        values: async (params: any) => {
          const graphQLClientWithHeaderItem: GraphQLClient =
            createGraphQLClientWithMiddleware("item");
          const { warehouses } = await graphQLClientWithHeaderItem.request(
            GET_ALL_WAREHOUSES
          );
          const warehousesName = warehouses.map(
            (warehouse: Warehouse) => warehouse.name
          );
          params.success(warehousesName);
        },
      },
    },
    {
      field: "source_bin_location_id",
      headerName: `รหัส${t("inventory.list.sourceBinLocation")}`,
      filter: "agSetColumnFilter",
      filterParams: {
        values: async (params: any) => {
          const graphQLClientWithHeaderItem: GraphQLClient =
            createGraphQLClientWithMiddleware("item");
          const { warehouseBinLocations } =
            await graphQLClientWithHeaderItem.request(GET_ALL_BIN_LOCATION);
          const binLocationsId = warehouseBinLocations.map(
            (binLocation: WarehouseBinLocation) => binLocation.id
          );
          params.success(binLocationsId);
        },
      },
    },
    {
      field: "source_bin_location_name",
      headerName: t("inventory.list.sourceBinLocation"),
      filter: "agSetColumnFilter",
      filterParams: {
        values: async (params: any) => {
          const graphQLClientWithHeaderItem: GraphQLClient =
            createGraphQLClientWithMiddleware("item");
          const { warehouseBinLocations } =
            await graphQLClientWithHeaderItem.request(GET_ALL_BIN_LOCATION);
          const binLocationsName = warehouseBinLocations.map(
            (binLocation: WarehouseBinLocation) => binLocation.bin_name
          );
          params.success(binLocationsName);
        },
      },
    },
    {
      field: "destination_warehouse_unique_id",
      headerName: t("inventory.list.destinationWarehouseDocId"),
      filter: "agSetColumnFilter",
      filterParams: {
        values: async (params: any) => {
          const graphQLClientWithHeaderItem: GraphQLClient =
            createGraphQLClientWithMiddleware("item");
          const { warehouses } = await graphQLClientWithHeaderItem.request(
            GET_ALL_WAREHOUSES
          );
          const warehousesUniqueId = warehouses.map(
            (warehouse: Warehouse) => warehouse.unique_id
          );
          params.success(warehousesUniqueId);
        },
      },
    },
    {
      field: "destination_warehouse_name",
      headerName: t("inventory.list.destinationWarehouse"),
      filter: "agSetColumnFilter",
      filterParams: {
        values: async (params: any) => {
          const graphQLClientWithHeaderItem: GraphQLClient =
            createGraphQLClientWithMiddleware("item");
          const { warehouses } = await graphQLClientWithHeaderItem.request(
            GET_ALL_WAREHOUSES
          );
          const warehousesName = warehouses.map(
            (warehouse: Warehouse) => warehouse.name
          );
          params.success(warehousesName);
        },
      },
    },
    {
      field: "destination_bin_location_id",
      headerName: `รหัส${t("inventory.list.destinationBinLocation")}`,
      filter: "agSetColumnFilter",
      filterParams: {
        values: async (params: any) => {
          const graphQLClientWithHeaderItem: GraphQLClient =
            createGraphQLClientWithMiddleware("item");
          const { warehouseBinLocations } =
            await graphQLClientWithHeaderItem.request(GET_ALL_BIN_LOCATION);
          const binLocationsId = warehouseBinLocations.map(
            (binLocation: WarehouseBinLocation) => binLocation.id
          );
          params.success(binLocationsId);
        },
      },
    },
    {
      field: "destination_bin_location_name",
      headerName: t("inventory.list.destinationBinLocation"),
      filter: "agSetColumnFilter",
      filterParams: {
        values: async (params: any) => {
          const graphQLClientWithHeaderItem: GraphQLClient =
            createGraphQLClientWithMiddleware("item");
          const { warehouseBinLocations } =
            await graphQLClientWithHeaderItem.request(GET_ALL_BIN_LOCATION);
          const binLocationsName = warehouseBinLocations.map(
            (binLocation: WarehouseBinLocation) => binLocation.bin_name
          );
          params.success(binLocationsName);
        },
      },
    },
    {
      field: "serial_no",
      headerName: t("inventory.serialNumber"),
      filter: "agTextColumnFilter",
    },
    {
      field: "barcode",
      headerName: t("inventory.barcode"),
      filter: "agTextColumnFilter",
    },
    {
      field: "lot_date",
      headerName: t("inventory.list.lot_number_thai"),
      filter: "agDateColumnFilter",
      filterParams: dateFilterParams,
      minWidth: 200,
      valueFormatter: (params: ValueFormatterParams) =>
        formatDate(params.value),
    },
    {
      field: "moved_by",
      headerName: t("inventory.items.transaction_by"),
      filter: "agTextColumnFilter",
    },
    {
      field: "created_by",
      headerName: t("inventory.stockEntry.createdBy"),
      filter: "agTextColumnFilter",
    },
  ];

  const graphQLClient: GraphQLClient =
    createGraphQLClientWithMiddleware("item");

  const datasource = {
    async getRows(params: IServerSideGetRowsParams) {
      const { request } = params;
      const { startRow, endRow, filterModel, sortModel } = request;
      const { created_date, posted_date, lot_date, ...otherFilter } =
        filterModel;
      const formatFilter = {
        ...otherFilter,
        created_date: dateFilterModel(created_date),
        posted_date: dateFilterModel(posted_date),
        lot_date: dateFilterModel(lot_date),
      };
      try {
        const { TraceEntryReportViews } = await graphQLClient.request(
          GET_TRACE_ENTRY_REPORT_VIEW,
          {
            aggridInput: {
              startRow,
              endRow,
              filterModel: formatFilter,
              sortModel,
            },
          }
        );
        params.success({
          rowData: TraceEntryReportViews.data as any[],
          rowCount: TraceEntryReportViews.count as number,
        });
      } catch (err) {
        params.fail();
      }
    },
  };

  const onGridReady = (params: GridReadyEvent) => {
    params.api.setServerSideDatasource(datasource);
    onFilterChanged(params);
  };

  return (
    <AgGrid
      height={665}
      ref={gridRef}
      columnDefs={columnDefs}
      onGridReady={onGridReady}
    />
  );
};

export default TraceEntryReportTable;
