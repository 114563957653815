import { IGoodsIssue } from "../../../../types/Inventory/goodsIssue";
import dayjs from "dayjs";
import * as Yup from "yup";
import { Tracability } from "../../../../generated/inventory";

export const goodsIssueSchema: IGoodsIssue = {
  unique_id: "",
  type: "",
  reference_unique_id: "",
  source_warehouse_unique_id: "",
  main_status: "",
  created_date: dayjs().toDate(),
  posted_date: dayjs(),
  remark: "",
  trace_entry_list: [],
  flag_status: [],
};

const goodsIssueTraceValidation = Yup.object().shape({
  stock_qty: Yup.number().nullable(),
  qty: Yup.number()
    .transform((value) =>
      isNaN(value) || value === null || value === undefined ? 0 : value
    )
    .min(1, "กรุณาระบุจำนวนที่จะนำออก")
    .required("กรุณาระบุจำนวนที่จะนำออก")
    .when(
      "stock_qty",
      (value: any, schema) =>
        (value || value === 0) &&
        schema.max(value, "กรุณาระบุจำนวนนำออกไม่เกินจำนวนในคลัง")
    ),
});

const goodsIssueSerialValidation = Yup.object().shape({
  stock_qty: Yup.number().nullable(),
  qty: Yup.number()
    .when("tracability", (tracability: any) =>
      tracability && tracability[0] === Tracability.Normal
        ? Yup.number()
            .transform((value) =>
              isNaN(value) || value === null || value === undefined ? 0 : value
            )
            .min(1, "กรุณาระบุจำนวนที่จะนำออก")
            .required("กรุณาระบุจำนวนที่จะนำออก")
        : Yup.number().nullable()
    )
    .when(
      "stock_qty",
      (value: any, schema) =>
        (value || value === 0) &&
        schema.max(value, "กรุณาระบุจำนวนนำออกไม่เกินจำนวนในคลัง")
    ),
  serial_list: Yup.array().when("tracability", (tracability: any) =>
    tracability && tracability[0] === Tracability.Serial
      ? Yup.array()
          .of(goodsIssueTraceValidation)
          .min(1, "กรุณาสแกนรายการสินค้า")
      : Yup.array().nullable()
  ),
});

export const goodsIssueValidation = Yup.object().shape({
  unique_id: Yup.string().required("กรุณาระบุเลขที่เอกสาร"),
  posted_date: Yup.date().typeError("กรุณาระบุ").required("กรุณาระบุ"),
  type: Yup.string().required("กรุณาระบุประเภท"),
  source_warehouse_unique_id: Yup.string().required("กรุณาระบุคลัง"),
  reference_unique_id: Yup.string().when("type", (type: any) => {
    if (type[0] && type[0] !== "other") {
      return Yup.string().required("กรุณาระบุเอกสารอ้างอิง");
    } else {
      return Yup.string();
    }
  }),
  trace_entry_list: Yup.array()
    .of(goodsIssueSerialValidation)
    .min(1, "กรุณาเพิ่มสินค้า"),
});
