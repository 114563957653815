import { GraphQLClient } from "graphql-request";
import { EntityTypeEnum } from "../../generated/creatable";
import {
  InventoryDocumentStatus,
  InventoryDocumentType,
  Tracability,
} from "../../generated/inventory";
import { ITEMS_SKU_AGGRID } from "../../services/AgGrid/InventoryAgGrid";
import { createGraphQLClientWithMiddleware } from "../../services/graphqlClient";
import { ITraceEntry } from "../../types/Inventory";
import { IUom } from "../../types/Inventory/item";
import { IPurchaseItemList } from "../../types/Purchase";
import { ISalesItemList } from "../../types/Sales";
import { v4 as uuidv4 } from "uuid";

export const statusValueFormatter = (status: string) => {
  switch (status) {
    case "draft":
      return "ร่าง";
    case "wait_transfer":
      return "รอโอนย้าย";
    case "wait_approve":
      return "รออนุมัติ";
    case "not_approved":
      return "ไม่อนุมัติ";
    case "approved":
      return "อนุมัติแล้ว";
    case "wait_accept":
      return "รอตอบรับ";
    case "accepted":
      return "ตอบรับแล้ว";
    case "wait_deliver":
      return "รอจัดส่ง";
    case "expired":
      return "เกินเวลา";
    case "partially_ordered":
      return "สั่งซื้อแล้วบางส่วน";
    case "fully_ordered":
      return "สั่งซื้อแล้ว";
    case "partially_imported":
      return "นำเข้าแล้วบางส่วน";
    case "fully_imported":
      return "นำเข้าแล้ว";
    case "completed":
      return "สำเร็จ";
    case "not_completed":
      return "ไม่สำเร็จ";
    case "pending_manu":
      return "รอผลิต";
    case "in_progress":
      return "กำลังผลิต";
    case "finished":
      return "เสร็จสิ้น";
    case "cancelled":
      return "ยกเลิก";
    default:
      return status;
  }
};

export const errorMessageFormatter = (error: any, type?: string) => {
  const formatError = JSON.stringify(error);
  if (formatError.includes("Unique constraint failed")) {
    if (
      formatError.includes(
        "Unique constraint failed on the fields: (`unique_id`)"
      )
    ) {
      if (type === "item") return "รหัสสินค้านี้มีอยู่ในระบบแล้ว กรุณาระบุใหม่";
      else if (type === "project")
        return "รหัสโครงการนี้มีอยู่ในระบบแล้ว กรุณาระบุใหม่";
      else if (type === "setting")
        return "ไม่สามารถเพิ่มหรือแก้ไขได้เนื่องจากมีข้อมูลอยู่แล้วในระบบ";
      else return "เลขที่เอกสารนี้มีอยู่ในระบบแล้ว";
    } else if (
      formatError.includes("Unique constraint failed on the fields: (`name`)")
    ) {
      if (type === "item") return "ชื่อสินค้านี้มีอยู่ในระบบแล้ว กรุณาระบุใหม่";
      else if (type === "project")
        return "ชื่อโครงการนี้มีอยู่ในระบบแล้ว กรุณาระบุใหม่";
      else if (type === "task")
        return "ชื่องานนี้มีอยู่ในระบบแล้ว กรุณาระบุใหม่";
      else if (type === "event")
        return "ชื่อเหตุการณ์นี้มีอยู่ในระบบแล้ว กรุณาระบุใหม่";
      else if (type === "setting")
        return "ไม่สามารถเพิ่มหรือแก้ไขได้เนื่องจากมีข้อมูลอยู่แล้วในระบบ";
    } else if (
      formatError.includes(
        "Unique constraint failed on the fields: (`barcode`)"
      )
    ) {
      if (type === "item") return "Barcode นี้มีอยู่ในระบบแล้ว กรุณาระบุใหม่";
      return "ไม่สามารถสร้างสินค้าได้เนื่องจาก Barcode ซ้ำ";
    }
  } else if (formatError.includes("is in use")) {
    return "ไม่สามารถลบได้เนื่องจากถูกใช้งานอยู่";
  }
};

export const errorMessageOnDeleteFormatter = (error: any, type?: string) => {
  const formatError = JSON.stringify(error);
  if (type === "project") {
    if (formatError.includes("PROJ_201")) {
      return "ไม่สามารถลบได้เนื่องจากโครงการนี้ถูกอ้างอิงในเอกสาร QA/SO/SR";
    } else if (formatError.includes("PROJ_202")) {
      return "ไม่สามารถลบได้เนื่องจากโครงการนี้ถูกอ้างอิงในเอกสาร PR/PO/RS";
    } else if (formatError.includes("PROJ_203")) {
      return "ไม่สามารถลบได้เนื่องจากโครงการนี้ถูกอ้างอิงในเอกสาร DO/DT";
    } else if (formatError.includes("PROJ_204")) {
      return "ไม่สามารถลบได้เนื่องจากโครงการนี้ถูกอ้างอิงในเอกสาร MO";
    }
  } else if (type === "event") {
    if (formatError.includes("cannot be deleted as its status")) {
      return "ไม่สามารถลบได้เนื่องจากเหตุการณ์นี้อยู่ในสถานะเสร็จสิ้นหรือยกเลิก";
    }
  }
  return "ไม่สามารถลบได้";
};

export const itemListToTraceEntryListFormatter = (
  item_list: (ISalesItemList | IPurchaseItemList)[],
  type: InventoryDocumentType,
  documentType: EntityTypeEnum
) => {
  const formatTraceEntry: ITraceEntry[] = item_list.map((item) => {
    let formatUom: IUom = {
      unique_id: item.uom || "",
      name: item.uom || "",
    };

    let formatPostedQty: number | undefined;

    if (
      documentType === EntityTypeEnum.SalesOrder ||
      documentType === EntityTypeEnum.PurchaseReturn
    ) {
      formatPostedQty = item.qty_issued;
    } else if (
      documentType === EntityTypeEnum.PurchaseOrder ||
      documentType === EntityTypeEnum.SalesReturn
    ) {
      formatPostedQty = item.qty_received;
    }

    return {
      item_unique_id: item.item_unique_id,
      item_name: item.item_name,
      item_img_url: item.item_img_url,
      tracability: item.tracability,
      type: type,
      unique_id: uuidv4(),
      document_item_qty: item.qty,
      reference_unique_id: item.unique_id,
      posted_qty: formatPostedQty,
      qty:
        type === InventoryDocumentType.GoodsReceive
          ? item.qty - (formatPostedQty || 0)
          : 0,
      uom: formatUom,
      serial_list: item.tracability === Tracability.Serial ? [] : undefined,
      status:
        item.tracability === Tracability.Normal
          ? InventoryDocumentStatus.IsActive
          : undefined,
      barcode: item.barcode || undefined,
    };
  });

  return formatTraceEntry;
};

export const itemListFormatter = async (
  item_list: (ISalesItemList | IPurchaseItemList)[]
) => {
  const graphQLClientWithHeaderItem: GraphQLClient =
    createGraphQLClientWithMiddleware("item");

  const allItemListUniqueId = item_list.map((item) => item.item_unique_id);
  const { itemSkuDetailsFindManyAggrid } =
    await graphQLClientWithHeaderItem.request(ITEMS_SKU_AGGRID, {
      aggridInput: {
        startRow: 0,
        endRow: allItemListUniqueId.length,
        filterModel: {
          sku_name: {
            filterType: "set",
            values: allItemListUniqueId,
          },
        },
      },
    });
  const { data: itemSkuDetails } = await itemSkuDetailsFindManyAggrid;

  let formatItemList: (ISalesItemList | IPurchaseItemList)[] = [];

  item_list.forEach((item) => {
    const foundItemIndex = itemSkuDetails.findIndex(
      (realItem: any) => realItem.sku_name === item.item_unique_id
    );

    formatItemList.push({
      ...item,
      uom_group: itemSkuDetails[foundItemIndex]?.item_sku.item.uom_group,
      tracability: itemSkuDetails[foundItemIndex]?.item_sku.item.tracability,
    });
  });

  return formatItemList;
};

export const characterRegExp: RegExp = /^[\u0E00-\u0E7Fa-zA-Z\d-]+$/;

export const formatContactName = (
  contact_type: string,
  contact_type_detail: string,
  title_name: string,
  main_contact_name: string,
  secondary_contact_name: string
) => {
  if (contact_type === "นิติบุคคล") {
    switch (contact_type_detail) {
      case "บริษัท":
        return `บริษัท ${main_contact_name} จำกัด`;
      case "บริษัทมหาชน":
        return `บริษัท ${main_contact_name} จำกัด (มหาชน)`;
      case "ห้างหุ้นส่วนจำกัด":
        return `ห้างหุ้นส่วนจำกัด ${main_contact_name}`;
      case "ห้างหุ้นส่วนสามัญนิติบุคคล":
        return `ห้างหุ้นส่วนสามัญนิติบุคคล ${main_contact_name}`;
      case "สมาคม":
        return `สมาคม ${main_contact_name}`;
      default:
        return main_contact_name;
    }
  } else if (contact_type === "บุคคลธรรมดา") {
    switch (contact_type_detail) {
      case "บุคคลธรรมดา":
        return `${title_name ?? ""}${main_contact_name}${
          secondary_contact_name ? ` ${secondary_contact_name}` : ""
        }`;
      case "ห้างหุ้นส่วนสามัญ":
        return main_contact_name;
      case "ร้านค้า":
        return `ร้าน ${main_contact_name}`;
      case "คณะบุคคล":
        return `คณะบุคคล ${main_contact_name}`;
      default:
        return main_contact_name;
    }
  }
  return main_contact_name;
};

export const formatTaxId = (taxId: string, branchName: string) => {
  if (taxId) {
    switch (branchName) {
      case "สำนักงานใหญ่":
        return `${taxId} (สำนักงานใหญ่)`;
      case "ไม่ระบุ":
        return taxId;
      default:
        return `${taxId} (${branchName})`;
    }
  } else {
    return "-";
  }
};

export const currencyOptions = [
  { id: 1, value: "THB", label: "THB - บาท" },
  { id: 2, value: "USD", label: "USD - ดอลลาร์สหรัฐ" },
  { id: 3, value: "EUR", label: "EUR - ยูโร" },
  { id: 4, value: "CNY", label: "CNY - หยวน" },
  { id: 5, value: "JPY", label: "JPY - เยน" },
  { id: 6, value: "GBP", label: "GBP - ปอนด์สเตอร์ลิง" },
  { id: 7, value: "SGD", label: "SGD - ดอลลาร์สิงคโปร์" },
  { id: 8, value: "AUD", label: "AUD - ดอลลาร์ออสเตรเลีย" },
  { id: 9, value: "KRW", label: "KRW - วอนเกาหลีใต้" },
  { id: 10, value: "INR", label: "INR - รูปี" },
  { id: 11, value: "HKD", label: "HKD - ดอลลาร์ฮ่องกง" },
];
export const salesOrderPdfTypeThaiToEng: Record<string, string> = {
  "ใบเสร็จรับเงิน/ใบกำกับภาษี": "tax-receipt",
  "ใบแจ้งหนี้/ใบกำกับภาษี": "sales-invoice",
  ใบกำกับภาษี: "tax-invoice",
  ใบเสร็จรับเงิน: "receipt",
};

export const salesOrderPdfTypeEngToThai: Record<string, string> = {
  "tax-receipt": "ใบเสร็จรับเงิน/ใบกำกับภาษี",
  "sales-invoice": "ใบแจ้งหนี้/ใบกำกับภาษี",
  "tax-invoice": "ใบกำกับภาษี",
  receipt: "ใบเสร็จรับเงิน",
};

export const salesOrderPdfTypeEngToEng: Record<string, string> = {
  "tax-receipt": "Receipt / Tax Invoice",
  "sales-invoice": "Sales Invoice / Tax Invoice",
  "tax-invoice": "Tax Invoice",
  receipt: "Receipt",
};
