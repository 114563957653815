import { gql } from "graphql-request";

export const PURCHASE_REQUESTS_AGGRID = gql`
  query PurchaseRequestsFindManyAggrid($aggridInput: AggridTemplateInput) {
    purchaseRequestsFindManyAggrid(aggridInput: $aggridInput) {
      count
      data {
        unique_id
        external_reference_id
        destination_warehouse_unique_id
        reference_unique_id_list
        related_user_list {
          user_unique_id
          first_name
          last_name
          email
          img_url
        }
        created_by {
          user_unique_id
          first_name
          last_name
          email
          img_url
        }
        created_date
        updated_date
        issue_date
        due_date
        requestor_contact {
          name
          department
          position
        }
        template_remark_id
        remark
        main_status
        sub_status
        flag_status
        aggrid_status
        item_list {
          unique_id
          reference_unique_id
          reference_document_type
          item_unique_id
          item_name
          item_sku_desc
          barcode
          po_qty
          qty
          qty_received
          price_per_unit
          discount
          vat_percentage
          pre_vat_amount
          withholding_tax_type
          withholding_tax_value
          remark
          stock_qty
          uom
        }
      }
    }
  }
`;

export const PURCHASE_ORDERS_AGGRID = gql`
  query PurchaseOrdersFindManyAggrid($aggridInput: AggridTemplateInput) {
    purchaseOrdersFindManyAggrid(aggridInput: $aggridInput) {
      count
      data {
        unique_id
        external_reference_id
        destination_warehouse_unique_id
        reference_unique_id_list
        related_user_list {
          user_unique_id
          first_name
          last_name
          email
          img_url
        }
        created_by {
          user_unique_id
          first_name
          last_name
          email
          img_url
        }
        created_date
        updated_date
        issue_date
        expect_date
        due_date
        credit_day
        price_vat_type
        pre_vat_amount
        additional_discount
        additional_discount_type
        shipping_cost
        vat_exempted_amount
        vat_0_amount
        vat_7_amount
        vat_amount
        net_amount
        withholding_tax_amount
        total_amount
        template_remark_id
        remark
        project {
          unique_id
          name
        }
        vendor_contact_unique_id
        vendor_contact {
          billing_address {
            address_type
            is_default
            is_same_as_default_address
            address_contact_name
            address_contact_phone
            address
            sub_district
            district
            province
            postal_code
            country
          }
          delivery_address {
            address_type
            is_default
            is_same_as_default_address
            address_contact_name
            address_contact_phone
            address
            sub_district
            district
            province
            postal_code
            country
          }
          email
          fax
          identity_no
          branch
          phone
          name
        }
        main_status
        sub_status
        flag_status
        aggrid_status
        item_list {
          unique_id
          reference_unique_id
          reference_document_type
          item_unique_id
          item_name
          item_sku_desc
          po_qty
          qty
          qty_received
          pr_reference_unique_id
          price_per_unit
          discount
          vat_percentage
          pre_vat_amount
          withholding_tax_type
          withholding_tax_value
          remark
          stock_qty
          uom
        }
      }
    }
  }
`;

export const PURCHASE_RETURNS_AGGRID = gql`
  query PurchaseReturnsFindManyAggrid($aggridInput: AggridTemplateInput) {
    purchaseReturnsFindManyAggrid(aggridInput: $aggridInput) {
      count
      data {
        unique_id
        external_reference_id
        destination_warehouse_unique_id
        reference_unique_id_list
        related_user_list {
          user_unique_id
          first_name
          last_name
          email
          img_url
        }
        created_by {
          user_unique_id
          first_name
          last_name
          email
          img_url
        }
        created_date
        updated_date
        issue_date
        delivery_date
        credit_day
        reason_to_return
        price_vat_type
        pre_vat_amount
        additional_discount
        vat_exempted_amount
        vat_0_amount
        vat_7_amount
        vat_amount
        net_amount
        withholding_tax_amount
        total_amount
        template_remark_id
        remark
        vendor_contact_unique_id
        vendor_contact {
          name
          identity_no
          branch
          email
          phone
          fax
          billing_address {
            address_type
            is_default
            is_same_as_default_address
            address_contact_name
            address_contact_phone
            address
            sub_district
            district
            province
            postal_code
            country
          }
          delivery_address {
            address_type
            is_default
            is_same_as_default_address
            address_contact_name
            address_contact_phone
            address
            sub_district
            district
            province
            postal_code
            country
          }
        }
        main_status
        sub_status
        flag_status
        aggrid_status
        item_list {
          reference_unique_id
          reference_document_type
          item_unique_id
          item_name
          item_sku_desc
          qty
          po_qty
          qty_ordered
          qty_received
          qty_returned
          uom
          price_per_unit
          discount
          vat_percentage
          pre_vat_amount
          stock_qty
          withholding_tax_type
          withholding_tax_value
          remark
          qty_issued
        }
        tag_list {
          entity
          id
          name
        }
      }
    }
  }
`;

export const PURCHASE_ITEM_PRICE_HISTORY_AGGRID = gql`
  query PurchaseItemPriceHistoryFindManyViewAggrid(
    $aggridInput: AggridTemplateInput
  ) {
    purchaseItemPriceHistoryFindManyViewAggrid(aggridInput: $aggridInput) {
      count
      data {
        document_type
        reference_unique_id
        vendor_contact_unique_id
        vendor_full_name
        document_created_date
        document_issue_date
        item_unique_id
        item_name
        qty
        uom
        price_per_unit
        discount
        discount_type
        vat_percentage
        remark
        status
      }
    }
  }
`;
