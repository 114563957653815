import {
  ColDef,
  GridReadyEvent,
  IServerSideGetRowsParams,
  RowDoubleClickedEvent,
} from "ag-grid-community";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
} from "react";
import { SALES_ORDER_VIEW_AGGRID } from "../../../../services/AgGrid/SalesAgGrid";
import { ISalesOrder } from "../../../../types/Sales/salesOrder";
import { dateFilterModel } from "../../../../utils/Formatter/AgGridFilter";
import { useSalesOrderColumnDefs } from "./columnDefs";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../../../../services/graphqlClient";
import { useStateContext } from "../../../../contexts/auth-context";
import { AgGridReact } from "ag-grid-react";
import AgGrid from "../../../UI/AgGrid";
// import ConfigurableAgGrid, {
//   ConfigurableAgGridReact,
// } from "../../../UI/ConfigurableAgGrid/ConfigurableAgGrid";

interface Props {
  isFilter?: string | null;
  referenceUniqueId?: string;
}

export type SaleOrderTableType = {
  setMode: (mode: "everyone" | "onlyme") => void;
};

const SalesOrderTable = forwardRef(
  ({ isFilter, referenceUniqueId }: Props, ref) => {
    const gridRef = useRef<AgGridReact<ISalesOrder>>(null);
    const { t } = useTranslation();
    const navigate = useNavigate();

    // useImperativeHandle(ref, () => {
    //   return {
    //     setMode: (mode: "everyone" | "onlyme") => {
    //       gridRef.current?.setMode(mode);
    //     },
    //   };
    // });

    const {
      state: { permissions, authUser },
    } = useStateContext();

    const columnDef: ColDef[] | undefined = useSalesOrderColumnDefs(t, false);

    const graphQLClientWithHeaderSales: GraphQLClient =
      createGraphQLClientWithMiddleware("sales");

    const datasource = {
      async getRows(params: IServerSideGetRowsParams) {
        const { request } = params;
        const { startRow, endRow, filterModel, sortModel } = request;
        const {
          created_date,
          issue_date,
          due_date,
          delivery_date,
          related_user_object_list,
          created_by_object,
          ...otherFilterModel
        } = filterModel;

        const orConditions = [
          {
            created_by_unique_id: {
              filterType: "text",
              type: "equals",
              filter: authUser?.unique_id,
            },
          },
          {
            related_user_unique_id_list: {
              filterType: "array",
              type: "hasSome",
              values: [authUser?.unique_id],
            },
          },
        ];

        const formatFilter = {
          ...otherFilterModel,
          created_date: dateFilterModel(created_date),
          issue_date: dateFilterModel(issue_date),
          due_date: dateFilterModel(due_date),
          delivery_date: dateFilterModel(delivery_date),
          created_by: created_by_object,
          _or:
            permissions?.sales_order.view === "SELF"
              ? {
                  _orArray: orConditions,
                }
              : undefined,
          related_user_list: related_user_object_list
            ? {
                filterType: "array",
                type: "hasSome",
                values: related_user_object_list.values,
              }
            : undefined,
          reference_unique_id_list: referenceUniqueId
            ? {
                filterType: "array",
                type: "hasSome",
                values: [referenceUniqueId],
              }
            : undefined,
        };
        try {
          const { salesOrdersViewFindManyAggrid } =
            await graphQLClientWithHeaderSales.request(
              SALES_ORDER_VIEW_AGGRID,
              {
                aggridInput: {
                  startRow,
                  endRow,
                  filterModel: formatFilter,
                  sortModel,
                },
              }
            );
          params.success({
            rowData: salesOrdersViewFindManyAggrid.data as any[],
            rowCount: salesOrdersViewFindManyAggrid.count as number,
          });
        } catch (err) {
          params.fail();
        }
      },
    };

    const onFilterChanged = useCallback(
      (params: any) => {
        const instance = params.api.getFilterInstance("aggrid_status");
        switch (isFilter) {
          case "draft":
            instance?.setModel({ values: ["draft"] });
            break;
          case "wait_approve":
            instance?.setModel({ values: ["wait_approve"] });
            break;
          case "not_approved":
            instance?.setModel({ values: ["not_approved"] });
            break;
          case "approved":
            instance?.setModel({ values: ["approved"] });
            break;
          case "finished":
            instance?.setModel({ values: ["finished"] });
            break;
          case "cancelled":
            instance?.setModel({ values: ["cancelled"] });
            break;
          case "expired":
            instance?.setModel({ values: ["expired"] });
            break;
          default:
            instance?.setModel({});
            break;
        }
        params.api.onFilterChanged();
      },
      [isFilter]
    );

    const onGridReady = (params: GridReadyEvent) => {
      onFilterChanged(params);
      params.api.setServerSideDatasource(datasource);
    };

    useEffect(() => {
      if (gridRef.current && gridRef.current.api) {
        onFilterChanged(gridRef.current);
      }
    }, [gridRef, onFilterChanged]);

    const onRowDoubleClicked = (params: RowDoubleClickedEvent) => {
      navigate(`/sales/order/${encodeURIComponent(params.data.unique_id)}`);
    };

    return (
      <AgGrid
        ref={gridRef}
        columnDefs={columnDef}
        height={665}
        onRowDoubleClicked={onRowDoubleClicked}
        onGridReady={onGridReady}
        path={"/sales/order"}
        // persistKey={"sale-order-table"}
      />
    );
  }
);

export default SalesOrderTable;
