import { useTranslation } from "react-i18next";
import { IBreadcrumbsAndMenu } from "../../../types/global";
import { useParams, useLocation } from "react-router";
import GoodsReceiveHeader from "../../../components/Form/Inventory/GoodsReceive/Header";
import { useFieldArray, useForm, useWatch } from "react-hook-form";
import { useEffect, useState } from "react";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import GoodsReceiveDetail from "../../../components/Form/Inventory/GoodsReceive/Detail";
import GoodsReceiveItemList from "../../../components/Table/Inventory/GoodsReceive/GoodsReceiveItemList";
import Confirmation from "../../../components/UI/Confirmation";
import { useConfirmation } from "../../../hooks/use-confrimation";
import { IGoodsReceive } from "../../../types/Inventory/goodsReceive";
import {
  goodsReceiveSchema,
  goodsReceiveValidation,
} from "../../../components/Form/Inventory/GoodsReceive/schema";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import { useSnackbar } from "notistack";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import {
  GoodsReceiveCreateInput,
  GoodsReceiveQuery,
  GoodsReceiveUpdateInput,
  InventoryDocumentStatus,
  InventoryDocumentType,
  WarehousesQuery,
  useGoodsReceiveCreateMutation,
  useGoodsReceiveQuery,
  useGoodsReceiveUpdateMutation,
  useInventoryDocumentNextStatusMutation,
  useWarehousesQuery,
  useItemSkuDetailsQuery,
  ItemSkuDetailsQuery,
  ActivityType,
  InventoryActivityLogDocumentType,
  Status,
} from "../../../generated/inventory";
import {
  goodsReceiveCreatePayloadFormatter,
  goodsReceiveQueryFormatter,
  goodsReceiveUpdatePayloadFormatter,
} from "../../../utils/Formatter/GoodsReceive";
import { useNavigate } from "react-router-dom";
import {
  Box,
  CircularProgress,
  Grid,
  Theme,
  useMediaQuery,
  IconButton,
  Stack,
} from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import { useInventoryError } from "../../../hooks/Inventory/use-inventory-error";
import { errorMessageFormatter } from "../../../utils/Formatter/Global";
import GoodsReceiveMobile from "./Mobile";
import { CustomizedBox } from "../../../components/Custom/CustomizedBox";
import { GraphQLClient } from "graphql-request";
import { useActivityLog } from "../../../hooks/use-activity-log";
import { ActivityLogSlideInPanel } from "../../../components/UI/SlideInPanel/ActivityLogSlideInPanel";
import RestoreOutlinedIcon from "@mui/icons-material/RestoreOutlined";
import { CustomizedTooltip } from "../../../components/Custom/CustomizedTooltip";
import { useRoleSelfPermission } from "../../../hooks/use-role-permission";
import BottomNavbar from "../../../components/UI/Navbar/BottomNavbar";

const GoodsReceiveContainer = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const { t } = useTranslation();
  const [step, setStep] = useState<number>(1);

  const [snackbarMessage, setSnackbarMessage] = useState<string>("");
  const [disabled, setDisabled] = useState<boolean>(false);

  const [itemIds, setItemIds] = useState<string[]>([]);
  const [itemIdsSnapshot, setItemIdsSnapshot] = useState<string[]>([]);
  const [initialTraceItems, setInitialTraceItems] = useState<string[]>([]);
  const [existingInitialTraceItems, setExistingInitialTraceItems] = useState<
    string[]
  >([]);

  const [showActivityLog, setShowActivityLog] = useState(false);
  const { createActivityLog } = useActivityLog();

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("inventory.index"),
      to: "/inventory",
    },
    {
      name: t("inventory.goods_receive.index"),
      to: "/inventory/goods_receive",
    },
    {
      name: Boolean(id)
        ? `${id}`
        : `${t("button.create")}${t("inventory.goods_receive.index")}`,
    },
  ];

  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
    reset,
  } = useForm<IGoodsReceive>({
    defaultValues: goodsReceiveSchema,
    resolver: yupResolver(goodsReceiveValidation),
  });

  const createdBy = useWatch({ control, name: "created_by" });

  useRoleSelfPermission(createdBy);

  const {
    fields,
    append,
    remove,
    update: updateFieldArray,
    replace,
  } = useFieldArray({
    control,
    name: "trace_entry_list",
  });

  useEffect(() => {
    if (state) {
      reset(state);
    }
  }, [reset, state]);

  const graphQLClientWithHeaderItem: GraphQLClient =
    createGraphQLClientWithMiddleware("item");

  const { data, isLoading, refetch } = useGoodsReceiveQuery<GoodsReceiveQuery>(
    graphQLClientWithHeaderItem,
    {
      uniqueInput: {
        unique_id: id,
      },
    },
    {
      enabled: !!id,
    }
  );

  const goodsReceiveId = data?.goodsReceive?.id;

  const { refetch: fetchItemSkuDetails } =
    useItemSkuDetailsQuery<ItemSkuDetailsQuery>(
      graphQLClientWithHeaderItem,
      {
        findManyInput: {
          sku_name: {
            in: data?.goodsReceive?.trace_entry_list?.map(
              (trace: any) => trace?.item_unique_id
            ),
          },
        },
      },
      {
        enabled: false,
      }
    );

  const { data: warehouseData, isLoading: isWarehouseLoading } =
    useWarehousesQuery<WarehousesQuery>(graphQLClientWithHeaderItem);

  useEffect(() => {
    if (data) {
      const traceItems =
        data?.goodsReceive?.trace_entry_list?.map(
          (trace: any) => trace.item_unique_id
        ) ?? [];
      setInitialTraceItems(traceItems);
      const getItemSkuDetails = async () => {
        const { data: result } = await fetchItemSkuDetails();
        const existingSkuNames =
          result?.itemSkuDetails?.map((sku: any) => sku.sku_name) ?? [];
        setExistingInitialTraceItems(existingSkuNames);
      };
      getItemSkuDetails();
    }
  }, [data, fetchItemSkuDetails]);

  useEffect(() => {
    const getGoodsReceive = async () => {
      const { data, isSuccess } = await refetch();
      if (isSuccess) {
        const { goodsReceive } = data;
        const goodsReceiveType = goodsReceive as IGoodsReceive;
        const formattedGoodsReceive = goodsReceiveQueryFormatter(
          goodsReceiveType,
          step
        );
        const currentStep = formattedGoodsReceive.draft_step_no;
        setStep(currentStep);
        const allItemIds = formattedGoodsReceive.trace_entry_list.map(
          (item) => item.item_unique_id
        );
        setItemIds(allItemIds);
        setItemIdsSnapshot(allItemIds);
        reset(formattedGoodsReceive);
      }
    };
    if (id) getGoodsReceive();
  }, [refetch, reset, step, id]);

  const { isLoading: isCreating, mutateAsync: create } =
    useGoodsReceiveCreateMutation<Error>(graphQLClientWithHeaderItem, {
      onSuccess: async ({ goodsReceiveCreate }) => {
        if (goodsReceiveCreate) {
          try {
            await createActivityLog({
              activity_type: ActivityType.StatusChange,
              document_type: InventoryActivityLogDocumentType.GoodsReceive,
              reference_id: goodsReceiveCreate.id,
              activity_detail: {
                secondary_operation: ActivityType.Create,
                curr_status: "draft" as Status,
              },
            });
          } catch (e) {
            console.log("Cannot create activity log");
          }
          enqueueSnackbar("สร้างใบนำเข้าสำเร็จ", {
            variant: "success",
          });
          navigate(`/inventory/goods_receive/${goodsReceiveCreate?.unique_id}`);
        }
      },
      onError: (err) => {
        const duplicatedUniqueId = errorMessageFormatter(err);
        if (duplicatedUniqueId) {
          enqueueSnackbar(duplicatedUniqueId, {
            variant: "error",
          });
        } else {
          enqueueSnackbar("สร้างใบนำเข้าไม่สำเร็จ", {
            variant: "error",
          });
        }
      },
    });

  const { isLoading: isUpdating, mutateAsync: update } =
    useGoodsReceiveUpdateMutation<Error>(graphQLClientWithHeaderItem, {
      onSuccess: async ({ goodsReceiveUpdate }) => {
        if (goodsReceiveUpdate) {
          try {
            await createActivityLog({
              activity_type: ActivityType.Edit,
              document_type: InventoryActivityLogDocumentType.GoodsReceive,
              reference_id: goodsReceiveUpdate.id!,
              activity_detail: {},
            });
          } catch (e) {
            console.log("Cannot create activity log");
          }
        }
        if (snackbarMessage !== "ย้อนกลับ" && snackbarMessage !== "ดำเนินต่อ") {
          enqueueSnackbar(`${snackbarMessage}สำเร็จ`, {
            variant: "success",
          });
        }
      },
      onError: (err) => {
        console.error(err);
        enqueueSnackbar(`${snackbarMessage}ไม่สำเร็จ`, {
          variant: "error",
        });
      },
    });

  const { isLoading: isChanging, mutateAsync: updateStatus } =
    useInventoryDocumentNextStatusMutation<Error>(graphQLClientWithHeaderItem, {
      onSuccess: ({ documentNextStatus }) => {
        if (documentNextStatus) {
          try {
            createActivityLog({
              activity_type: ActivityType.StatusChange,
              document_type: InventoryActivityLogDocumentType.GoodsReceive,
              reference_id: goodsReceiveId!,
              activity_detail: {
                curr_status: documentNextStatus.status_name as Status,
              },
            });
          } catch (e) {
            console.log("Cannot create activity log");
          }
        }
        refetch();
      },
    });

  useEffect(() => {
    if (
      !isLoading &&
      !isWarehouseLoading &&
      id &&
      data?.goodsReceive?.aggrid_status !== "draft"
    ) {
      setDisabled(true);
    }
  }, [data?.goodsReceive?.aggrid_status, id, isLoading, isWarehouseLoading]);

  const onGoodsReceiveCreate = async (
    data: IGoodsReceive,
    status: string,
    step: number
  ) => {
    const payload = goodsReceiveCreatePayloadFormatter(
      data
    ) as GoodsReceiveCreateInput;
    await create({
      createInput: { ...payload, sub_status: status, draft_step_no: step },
    });
  };

  const onGoodsReceiveUpdate = async (data: IGoodsReceive) => {
    //check whether trace still have is_scan false or not
    if (data) {
      if (data.main_status === "draft" && step === 1) {
        const traces = data.trace_entry_list.map(
          (trace) => trace.item_unique_id
        );
        const matchingIntialTraces = traces.filter((trace) =>
          initialTraceItems.includes(trace)
        );
        const tracesToBeDeleted = matchingIntialTraces.filter(
          (trace) => !existingInitialTraceItems.includes(trace)
        );
        if (tracesToBeDeleted.length > 0) {
          enqueueSnackbar(
            "เอกสารมีสินค้าที่ถูกลบออกจากระบบแล้ว\nกรุณาลบสินค้า",
            {
              variant: "error",
              style: { whiteSpace: "pre-line" },
            }
          );
          return;
        }
      }
      const payload = goodsReceiveUpdatePayloadFormatter(
        data,
        step
      ) as GoodsReceiveUpdateInput;
      setSnackbarMessage("บันทึกร่าง");
      await update({
        uniqueInput: {
          unique_id: id,
        },
        updateInput: payload,
      });
    }
  };

  const onGoodsReceiveImport = async (data: IGoodsReceive) => {
    if (data) {
      const { trace_entry_list } = data;
      if (
        trace_entry_list.length > 0 &&
        trace_entry_list.some((trace) => trace.status === "is_active")
      ) {
        openConfirmationHandler();
      } else if (
        trace_entry_list.some((trace) =>
          trace.serial_list?.some((serial) => serial.status === "is_active")
        )
      ) {
        openConfirmationHandler();
      } else {
        const payload = goodsReceiveUpdatePayloadFormatter(
          data,
          step
        ) as GoodsReceiveUpdateInput;
        setSnackbarMessage("นำเข้า");

        await update({
          uniqueInput: {
            unique_id: id,
          },
          updateInput: { ...payload, sub_status: "finished" },
        });
        await updateStatus({
          documentInput: {
            reference_document_type: InventoryDocumentType.GoodsReceive,
            unique_id: data.unique_id,
          },
        });
      }
    }
  };

  const onGoodsReceiveNextStep = async (data: IGoodsReceive) => {
    if (data) {
      if (!id) {
        await onGoodsReceiveCreate(data, "draft", 2);
      } else {
        const payload = goodsReceiveUpdatePayloadFormatter(
          data,
          step
        ) as GoodsReceiveUpdateInput;
        setSnackbarMessage("ดำเนินต่อ");
        await update({
          uniqueInput: {
            unique_id: id,
          },
          updateInput: { ...payload, draft_step_no: 2 },
        });
      }
      setStep(2);
    }
  };

  const onGoodsReceivePreviousStep = async (data: IGoodsReceive) => {
    if (data) {
      const payload = goodsReceiveUpdatePayloadFormatter(data, step);
      setSnackbarMessage("ย้อนกลับ");
      const { trace_entry_list, ...otherPayload } = payload;
      const formatTrace = trace_entry_list.map(
        ({
          scanned_by,
          posted_date,
          status,
          source_bin_location,
          source_bin_location_id,
          ...otherTrace
        }) => ({
          ...otherTrace,
          status: InventoryDocumentStatus.IsActive,
        })
      );
      const formatPayload = {
        ...otherPayload,
        trace_entry_list: formatTrace,
        draft_step_no: 1,
      } as GoodsReceiveUpdateInput;

      await update({
        uniqueInput: {
          unique_id: id,
        },
        updateInput: formatPayload,
      });
      setStep(1);
    }
  };

  const onSubmitConfirmation = async () => {
    const data: IGoodsReceive = getValues();
    const payload = goodsReceiveUpdatePayloadFormatter(
      data,
      step
    ) as GoodsReceiveUpdateInput;
    setSnackbarMessage("นำเข้า");
    await update({
      uniqueInput: {
        unique_id: id,
      },
      updateInput: { ...payload, sub_status: "finished" },
    });
    await updateStatus({
      documentInput: {
        reference_document_type: InventoryDocumentType.GoodsReceive,
        unique_id: data.unique_id,
      },
    });
  };

  const {
    confirmation,
    openConfirmationHandler,
    closeConfirmationHandler,
    submitConfirmationHandler,
  } = useConfirmation(onSubmitConfirmation);

  const renderButton = () => {
    switch (data?.goodsReceive?.aggrid_status) {
      case "draft":
        if (step === 1) {
          if (isMobile) {
            return (
              <Grid container spacing={1} mt={3}>
                <Grid item xs={6}>
                  <CustomizedButton
                    fullWidth
                    size="large"
                    variant="outlined"
                    title={t("button.save_draft")}
                    disabled={isUpdating}
                    onClick={handleSubmit(onGoodsReceiveUpdate)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <CustomizedButton
                    fullWidth
                    size="large"
                    title={t("button.next")}
                    variant="contained"
                    onClick={handleSubmit(onGoodsReceiveNextStep)}
                    disabled={isUpdating}
                  />
                </Grid>
              </Grid>
            );
          } else {
            return (
              <Stack direction="row" spacing={1} alignItems="center">
                <CustomizedButton
                  variant="outlined"
                  title={t("button.save_draft")}
                  disabled={isUpdating}
                  onClick={handleSubmit(onGoodsReceiveUpdate)}
                />
                <CustomizedButton
                  title={t("button.next")}
                  variant="contained"
                  onClick={handleSubmit(onGoodsReceiveNextStep)}
                  disabled={isUpdating}
                />
              </Stack>
            );
          }
        } else {
          if (isMobile) {
            return (
              <Grid container spacing={1} mt={3}>
                <Grid item xs={6}>
                  <CustomizedButton
                    fullWidth
                    size="large"
                    variant="outlined"
                    title={t("button.back")}
                    disabled={isUpdating}
                    onClick={handleSubmit(onGoodsReceivePreviousStep)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <CustomizedButton
                    fullWidth
                    size="large"
                    title={t("button.save_draft")}
                    variant="outlined"
                    onClick={handleSubmit(onGoodsReceiveUpdate)}
                    disabled={isUpdating}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CustomizedButton
                    fullWidth
                    size="large"
                    title={t("inventory.goods_receive.index")}
                    variant="contained"
                    onClick={handleSubmit(onGoodsReceiveImport)}
                    disabled={isUpdating || isChanging}
                  />
                </Grid>
              </Grid>
            );
          }
        }
        return (
          <Stack direction="row" spacing={1} alignItems="center">
            <CustomizedButton
              variant="outlined"
              title={t("button.back")}
              disabled={isUpdating}
              onClick={handleSubmit(onGoodsReceivePreviousStep)}
            />
            <CustomizedButton
              title={t("button.save_draft")}
              variant="outlined"
              onClick={handleSubmit(onGoodsReceiveUpdate)}
              disabled={isUpdating}
            />
            <CustomizedButton
              title={t("inventory.goods_receive.index")}
              variant="contained"
              onClick={handleSubmit(onGoodsReceiveImport)}
              disabled={isUpdating || isChanging}
            />
          </Stack>
        );
      case "finished":
      case "cancelled":
        return null;
      default:
        if (isMobile) {
          return (
            <Grid container spacing={1} mt={3}>
              <Grid item xs={6}>
                <CustomizedButton
                  fullWidth
                  size="large"
                  variant="outlined"
                  title={t("button.save_draft")}
                  disabled={isCreating}
                  onClick={handleSubmit((data) =>
                    onGoodsReceiveCreate(data, "draft", 1)
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <CustomizedButton
                  fullWidth
                  size="large"
                  title={t("button.next")}
                  variant="contained"
                  onClick={handleSubmit(onGoodsReceiveNextStep)}
                  disabled={isUpdating}
                />
              </Grid>
            </Grid>
          );
        } else {
          return (
            <Stack direction="row" spacing={1} alignItems="center">
              <CustomizedButton
                variant="outlined"
                title={t("button.save_draft")}
                disabled={isCreating}
                onClick={handleSubmit((data) =>
                  onGoodsReceiveCreate(data, "draft", 1)
                )}
              />
              <CustomizedButton
                title={t("button.next")}
                variant="contained"
                onClick={handleSubmit(onGoodsReceiveNextStep)}
                disabled={isUpdating}
              />
            </Stack>
          );
        }
    }
  };

  useInventoryError(errors);

  if ((id && (isLoading || isUpdating)) || isWarehouseLoading) {
    return (
      <Box
        sx={{
          height: "calc(100dvh - 125px)",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return isMobile ? (
    <form
      onKeyDown={(e) => {
        if (e.code === "Enter" || e.code === "NumpadEnter") e.preventDefault();
      }}
    >
      {id && (
        <ActivityLogSlideInPanel
          open={showActivityLog}
          handleClose={() => setShowActivityLog(false)}
          documentId={goodsReceiveId!}
          documentType={InventoryActivityLogDocumentType.GoodsReceive}
        />
      )}
      <Box display="flex">
        <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />\
        <Box marginLeft="auto">
          <CustomizedTooltip title="ดูความเคลื่อนไหว" enterNextDelay={200}>
            <IconButton
              onClick={
                goodsReceiveId
                  ? () => {
                      setShowActivityLog(true);
                    }
                  : () => {}
              }
              sx={{
                color: (theme) => theme.palette.grey[500],
                padding: 0,
                marginLeft: "4px",
              }}
            >
              <RestoreOutlinedIcon />
            </IconButton>
          </CustomizedTooltip>
        </Box>
      </Box>

      <GoodsReceiveMobile
        control={control}
        disabled={disabled}
        errors={errors}
        getValues={getValues}
        refetch={refetch}
        setValue={setValue}
        step={step}
        data={data}
        reset={reset}
        warehouseData={warehouseData}
        itemIds={itemIds}
        setItemIds={setItemIds}
        itemIdsSnapshot={itemIdsSnapshot}
        setItemIdsSnapshot={setItemIdsSnapshot}
        confirmation={confirmation}
        closeConfirmationHandler={closeConfirmationHandler}
        submitConfirmationHandler={submitConfirmationHandler}
        renderButton={renderButton}
        append={append}
        fields={fields}
        remove={remove}
        update={updateFieldArray}
        replace={replace}
      />
    </form>
  ) : (
    <form
      onKeyDown={(e) => {
        if (e.code === "Enter" || e.code === "NumpadEnter") e.preventDefault();
      }}
    >
      {id && (
        <ActivityLogSlideInPanel
          open={showActivityLog}
          handleClose={() => setShowActivityLog(false)}
          documentId={goodsReceiveId!}
          documentType={InventoryActivityLogDocumentType.GoodsReceive}
        />
      )}
      <Box display="flex">
        <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
        <Box marginLeft="auto">
          <CustomizedTooltip title="ดูความเคลื่อนไหว" enterNextDelay={200}>
            <IconButton
              onClick={
                goodsReceiveId
                  ? () => {
                      setShowActivityLog(true);
                    }
                  : () => {}
              }
              sx={{
                color: (theme) => theme.palette.grey[500],
                padding: 0,
                marginLeft: "4px",
              }}
            >
              <RestoreOutlinedIcon />
            </IconButton>
          </CustomizedTooltip>
        </Box>
      </Box>
      <GoodsReceiveHeader
        control={control}
        getValues={getValues}
        setValue={setValue}
        disabled={disabled}
        errors={errors}
        status={data?.goodsReceive?.aggrid_status}
        mainStatus={data?.goodsReceive?.main_status}
        refetch={refetch}
      />
      <GoodsReceiveDetail
        control={control}
        getValues={getValues}
        setValue={setValue}
        disabled={disabled}
        errors={errors}
        status={data?.goodsReceive?.aggrid_status}
        reset={reset}
        allWarehouses={warehouseData?.warehouses || []}
        step={step}
        append={append}
      />
      <CustomizedBox margin="2rem 0 4rem 0">
        <GoodsReceiveItemList
          control={control}
          getValues={getValues}
          setValue={setValue}
          disabled={disabled}
          errors={errors}
          status={data?.goodsReceive?.aggrid_status}
          allWarehouses={warehouseData?.warehouses || []}
          step={step}
          goodReceiveData={data?.goodsReceive}
          itemIds={itemIds}
          setItemIds={setItemIds}
          itemIdsSnapshot={itemIdsSnapshot}
          setItemIdsSnapshot={setItemIdsSnapshot}
          initialTraceItems={initialTraceItems}
          existingInitialTraceItems={existingInitialTraceItems}
          fields={fields}
          remove={remove}
          update={updateFieldArray}
          replace={replace}
        />
      </CustomizedBox>
      {!["finished", "cancelled"].includes(
        data?.goodsReceive?.aggrid_status || ""
      ) && <BottomNavbar>{renderButton()}</BottomNavbar>}
      <Confirmation
        title="ยืนยันหากต้องการนำเข้าสินค้า"
        message="ขณะนี้มีสินค้า “รอสแกน” อยู่หากนำเข้าแล้วจะไม่สามารถแก้ไขเอกสารนี้ได้"
        open={confirmation}
        handleClose={closeConfirmationHandler}
        action={submitConfirmationHandler}
      />
    </form>
  );
};

export default GoodsReceiveContainer;
